/* eslint-disable no-restricted-syntax */
<script>
import { addClass } from '@/helpers/text-editor-helper.js';

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.block.fields.Title;
    },
    heading() {
      return this.block.fields.HeadingLevel?.value || 'h4';
    },
    subtitle() {
      return this.block.fields.Subtitle;
    },
    textContent() {
      return addClass(this.block.fields.TextContent, 'text-with-title');
    },
  },
};
</script>

<template>
  <div class="text-with-title__container">
    <div class="text-with-title__titles">
      <component :is="heading" v-if="title" class="text-with-title__title"> {{title}} </component>
      <p class="text-with-title__subtitle"> {{subtitle}} </p>
    </div>
    <div
      class="text-with-title__text-content text-editor-content"
      v-html="textContent"
    >
    </div>
  </div>
</template>

<style>
  .text-with-title__container {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }

  .text-with-title__titles {
    margin-bottom: 20px;
  }

  .text-with-title__title {
    margin: 0 0 4px;
  }

  .text-with-title__subtitle {
    line-height: 24px;
    margin: 0;
  }

  .text-with-title__text-element {
    margin: 20px 0 0;
  }

  ul.text-with-title__text-element {
    margin-top: 40px;
    padding: 0;
  }

  p.text-with-title__text-element {
    font-size: 16px;
    line-height: 24px;
  }

  ul.text-with-title__text-element > li {
    margin-top: 10px;
  }

  .text-with-title__text-element:first-child {
    margin-top: 0;
  }

  @media (--desktop) {
    .text-with-title__container {
      flex-direction: row;
      max-width: 1230px;
      margin: auto;
      justify-content: space-between;
    }

    .text-with-title__titles {
      flex-grow: 1;
      max-width: 606px;
      margin-right: 26px;
    }

    .text-with-title__title {
      margin: 0 0 20px;
    }

    .text-with-title__text-content {
      flex-grow: 1;
      max-width: 578px;
    }
  }
</style>
